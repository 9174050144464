import React from "react";

export default function Links() {
  return (
    <div className="links secondary-text">
      <a href="/" className="">
        Home
      </a>
      <a href="/#/videos/" className="">
        Videos
      </a>
      <a href="/#/events/" className="">
        Events
      </a>
      <a href="/#/resume/" className="">
        Resume
      </a>
      <a href="/#/gallery/" className="">
        Gallery
      </a>
      <a href="/#/contact/" className="">
        Contact
      </a>
    </div>
  );
}
