import React from "react";
import HeroAlternate from "../components/HeroAlternate";
import resumeImg1 from "../assets/lucyResume1.png";
import resumeImg2 from "../assets/lucyResume2.png";

export default function Resume() {
  return (
    <div>
      <HeroAlternate title="resume" />
      <div className="primary-background center-text" id="resume">
        <div className="container">
          <img src={resumeImg1} alt="" width="80%" />
          <img src={resumeImg2} alt="" width="80%" />
        </div>
      </div>
    </div>
  );
}
