const photoData = [
  {
    id: 6,
    path: 'eponine.jpg',
    description: 'Backstage as Éponine in "Les Misérables"',
  },
  {
    id: 5,
    path: 'segstrom.JPG',
    description: 'In costume as Mrs. Segstrom in "A Little Night Music" at Chapman University',
  },
  {
    id: 4,
    path: 'chapmanSingers.JPG',
    description: 'Performing with the Chapman University Singers',
  },
  {
    id: 3,
    path: 'mark.jpg',
    description: 'Performing a duet from "The Magic Flute" with baritone Mark Peng',
  },
  {
    id: 2,
    path: 'vineyard.jpeg',
    description: 'Singing at Cooper Ridge Vineyard',
  },
  {
    id: 1,
    path: 'michael.jpeg',
    description: 'Entertaining at a wedding with cellist Michael Krum',
  },
];

export default photoData;
