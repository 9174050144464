import React from "react";
import EventTableRow from "./EventTableRow";

export default function EventTable(props) {
  const bgColor = props.idx === 0 ? "primary" : "secondary";
  return (
    <div
      className={`event-schedule-area-two bg-color ${bgColor}-background event-table`}
      id="past-events-table"
    >
      <div className="container">
        <h3 className="event-list-title primary-text">{props.title}</h3>
        <div className="">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="home"
                  role="tabpanel"
                >
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="" scope="col">
                            Date
                          </th>
                          <th scope="col">Event</th>
                          <th scope="col">Location </th>
                          <th className="text-right" scope="col">
                            Website
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.data.map((event) => (
                          <EventTableRow
                            key={event.id}
                            id={event.id}
                            title={event.title}
                            day={event.day}
                            year={event.year}
                            time={event.time}
                            venue={event.venue}
                            address1={event.address1}
                            address2={event.address2}
                            website={event.website}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
