import React from "react";

export default function PhotoSection(props) {
  
  return (
    <div className="flexbox-vert gallery-tab">
      <img className="gallery-image" src={props.path} />
      <p className="secondary-text gallery-description">{props.description}</p>
    </div>
  );
}
