import React from "react";
import Links from "./Links";
import Dropdown from "./Dropdown";
import lfImg from "../assets/LF.png";

export default function Navbar() {
  return (
    <nav className="navbar-background">
      <div className="navbar container">
        <a href="/">
          <img src={lfImg} className="title-logo" alt="" />
        </a>
        <Links />
        <Dropdown />
      </div>
    </nav>
  );
}
