import React from "react";
import Hero from "../components/Hero";
import VideoSection from "../components/VideoSection";
import videoData from "../data/videoData";
import EventTable from "../components/EventTable";
import { pastEventData, upcomingEventData } from "../data/eventData";

export default function Home() {
  const video = videoData[0];
  const events = upcomingEventData.filter((event, index) => index < 3);
  return (
    <div>
      <Hero />
      <div id="video-home" className="">
        <VideoSection
          id={video.id}
          title={video.title}
          titleAlt={video.titleAlt}
          description={video.description}
          youtubeURL={video.youtubeURL}
        />
        <div id="videos-link" className="secondary-background">
          <div className="container">
            <a href="/#/videos/">Click here to see more videos!</a>
          </div>
        </div>
      </div>
      <div id="events-home" className="primary-background">
        <EventTable idx={0} title="Events" data={events} />
        {events.length === 0 && (
          <div className="primary-background">
            <p className="container center-text no-events">
              There are currently no upcoming events.
            </p>
          </div>
        )}
        <div id="events-link" className="primary-background">
          <div className="container">
            <a href="/#/events/">Click here to see more events!</a>
          </div>
        </div>
      </div>
    </div>
  );
}
