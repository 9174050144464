import React from "react";

export default function EventTableRow(props) {
  return (
    <tr className="inner-box">
      <th scope="row">
        <div className="event-date">
          <span>{props.year}</span>
          <p>{props.day}</p>
        </div>
      </th>
      <td>
        <div className="event-wrap">
          <h3>{props.title}</h3>
          <div className="time">
            <span>{props.time}</span>
          </div>
        </div>
      </td>
      <td>
        <div className="r-no">
          <p>{props.venue}</p>
          <span>
            {props.address1}
            <br />
            {props.address2}
          </span>
        </div>
      </td>
      <td>
        {props.website && (
          <div className="primary-btn">
            <a className="btn btn-secondary" href={props.website}>
              Go!
            </a>
          </div>
        )}
      </td>
    </tr>
  );
}
