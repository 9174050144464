import React from "react";

export default function VideoSection(props) {
  return props.id % 2 === 0 ? (
    <div className="primary-background primary-padding">
      <div className="container">
        <div className="flexbox-horiz">
          <div className="video-text flexbox-vert">
            <h2 className="secondary-text">{props.title}</h2>
            {props.titleAlt !== "" && (
              <h3 className="secondary-text">{props.titleAlt}</h3>
            )}
            <p className="secondary-text">{props.description}</p>
          </div>
          <iframe
            id={"video" + props.id}
            width="450"
            height="253"
            src={props.youtubeURL}
            title="YouTube video player"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="secondary-background primary-padding">
      <div className="container">
        <div className="flexbox-horiz">
          <iframe
            id={"video" + props.id}
            width="450"
            height="253"
            src={props.youtubeURL}
            title="YouTube video player"
            allowFullScreen
          />
          <div className="video-text flexbox-vert">
            <h2 className="secondary-text">{props.title}</h2>
            {props.titleAlt !== "" && (
              <h3 className="secondary-text">{props.titleAlt}</h3>
            )}
            <p className="secondary-text">{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
