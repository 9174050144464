import React from "react";
import menuImg from "../assets/menu.svg";

export default function Dropdown() {
  function dropDown() {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  // Close the dropdown menu if the user clicks outside of it
  window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  return (
    <div className="dropdown">
      <button onClick={dropDown} className="dropbtn">
        <img src={menuImg} className="dropbtn" alt="" />
      </button>
      <div id="myDropdown" className="dropdown-content">
        <a href="/" className="">
          Home
        </a>
        <a href="/#/videos/" className="">
          Videos
        </a>
        <a href="/#/events/" className="">
          Events
        </a>
        <a href="/#/resume/" className="">
          Resume
        </a>
        <a href="/#/gallery/" className="">
          Gallery
        </a>
        <a href="/#/contact/" className="">
          Contact
        </a>
      </div>
    </div>
  );
}
