import React from "react";
import HeroAlternate from "../components/HeroAlternate";
//import ContactForm from "../components/ContactForm";

export default function Contact() {
  return (
    <div>
      <HeroAlternate title="contact" />
      <div className="secondary-background primary-padding" >
        <h4 className="center-text">For all inquiries, please send an email here:</h4>
        <h5 className="center-text">lfrancosinger@gmail.com</h5>
      </div>    
    </div>
  );
}
