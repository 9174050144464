import React from "react";
import photoData from "../data/photoData";
import PhotoSection from "../components/PhotoSection";
import HeroAlternate from "../components/HeroAlternate";

export default function Gallery() {
  return (
    <div>
      <HeroAlternate title="gallery" />
      <div id="photos" className="secondary-background primary-padding">
        <div className="gallery container">
          {photoData.map((photo) => (
            <PhotoSection 
              key={photo.id}
              id={photo.id}
              description={photo.description}
              path={photo.path} 
              />
          ))}
        </div>
      </div>
    </div>
  );
}

